import { createSelector, createSlice } from '@reduxjs/toolkit';
import { API_HOST } from '../../config/settings';

const initialState = {
  list: null,
};

export const teamListSlice = createSlice({
  name: 'teamList',
  initialState: initialState,
  reducerPath: 'teamList',
  reducers: {
    setTeams: (state, action) => {
      state.list = action.payload.data;
    },
    logout: () => initialState,
  },
});

export const { setTeams, logout } = teamListSlice.actions;

export const requestTeamList = () => (dispatch, getState) => {
  fetch(`${API_HOST}/teams`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
      return response.json();
    })
    .then((teams) => {
      dispatch(setTeams(teams));
    })
    .catch((error) => {});
};

export const selectTeamFromTeamList = createSelector(
  [(state) => state.teamList.list, (state, teamId) => teamId],
  (list, teamId) => {
    console.log(teamId);
    console.log(list);
    return list.find((team) => team.id === teamId);
  }
);

export default teamListSlice.reducer;
