import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTeamAndAddUser } from '../redux/reducers/user-slice';

const CreateTeam = () => {
  const [formShown, setFormShown] = useState(false);
  const [teamName, setTeamName] = useState('');
  const dispatch = useDispatch();
  const assignedTeamName = useSelector((state) => state.user.team_name);

  const submitForm = (event) => {
    dispatch(createTeamAndAddUser(teamName));
    event.preventDefault();
  };

  if (formShown) {
    return (
      <form className="section" onSubmit={submitForm}>
        <h2 className="title">Neues Team</h2>
        <div className="block">
          Nachdem du ein Team erstellt hast, wirst du automatisch Mitglied.
        </div>
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="Euer Teamname"
              onChange={(event) => setTeamName(event.target.value)}
            />
          </div>
        </div>
        <div className="field">
          <button className="button is-primary" type="submit">
            erstellen
          </button>
        </div>
      </form>
    );
  }

  return (
    !assignedTeamName && (
      <button
        className="button is-primary is-rounded"
        onClick={() => setFormShown(true)}
      >
        Neues Team erstellen
      </button>
    )
  );
};

export default CreateTeam;
