import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const HeightChart = ({ plant, isOpenDefault }) => {
  const [chartOpen, setChartOpen] = useState(isOpenDefault);
  return (
    <div className="card is-shadowless is-rounded-edge">
      <header
        className="card-header pointer"
        onClick={() => setChartOpen(!chartOpen)}
      >
        <p className="card-header-title">
          Höhenverlauf {chartOpen ? 'ausblenden' : 'anzeigen'}
        </p>
        <button className="card-header-icon" aria-label="more options">
          <span className="icon">
            <i
              className={`fa ${chartOpen ? 'fa-angle-up' : 'fa-angle-down'}`}
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </header>
      {chartOpen && (
        <div className="height-chart card-content">
          <div class="content">
            <ReactApexChart
              type="line"
              height={200}
              options={{
                tooltip: {
                  y: {
                    formatter: (val) => val.toLocaleString('de-DE') + ' cm',
                  },
                },
                chart: {
                  id: `heights-${plant.id}`,
                },
                xaxis: {
                  categories: plant.height
                    .toReversed()
                    .map((height) => height.date),
                  labels: {
                    formatter: (value, timestamp, opts) => {
                      return new Date(value).toLocaleString();
                    },
                  },
                },
                yaxis: {
                  min: 0,
                  labels: {
                    formatter: (val, index) => {
                      return val.toLocaleString('de-DE');
                    },
                  },
                },
              }}
              series={[
                {
                  name: 'Höhe',
                  data: plant.height
                    .toReversed()
                    .map((height) => height.height_in_mm / 10),
                },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeightChart;
