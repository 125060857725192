import React from 'react';
import Profile from '../components/profile';
import TomatoList from '../components/tomato-list/tomato-list';
import { useSelector } from 'react-redux';
import UserNotifications from '../components/user-notifications';
import Teams from '../components/teams';

const Home = () => {
  const given_name = useSelector((store) => store.user.profile?.given_name);
  return (
    <div className="section pt-0">
      <h1 className="title">Hallo {given_name}!</h1>
      <h2 className="subtitle">Willkommen bei den Tomatenfreunden!</h2>
      <div className="columns">
        <div className="column is-12-mobile is-7-tablet is-offset-1-widescreen is-7-widescreen is-offset-1-fullhd is-5-fullhd">
          <TomatoList />
        </div>
        <div className="column is-5-tablet is-offset-0-widescreen is-4-widescreen is-offset-1-fullhd is-3-fullhd">
          <Profile />
          <div className="block">
            <UserNotifications />
          </div>
          <div className="block">
            <Teams />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
