import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendTomatoListItem } from '../../redux/reducers/tomato-list-slice';

const AddItem = () => {
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formText, setFormText] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!photo) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(photo);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [photo]);

  const renderWaitingOverlay = () => {
    return (
      formSubmitted && (
        <div className="waiting-overlay p-3">
          <progress className="progress is-small is-primary" max="100">
            15%
          </progress>
        </div>
      )
    );
  };

  return (
    <article className="message is-relative">
      {renderWaitingOverlay()}
      <div className="message-body is-rounded-edge">
        <form
          onSubmit={(e) => {
            setFormDisabled(true);
            setFormSubmitted(true);
            dispatch(
              sendTomatoListItem({
                text: formText,
                photo: photo,
                onSuccess: () => {
                  setFormDisabled(false);
                  setPhoto(null);
                  setFormText('');
                  setFormSubmitted(false);
                },
              })
            );
            e.preventDefault();
          }}
        >
          <div className="field">
            <div className="control">
              <div className="block is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
                <input
                  type="file"
                  onChange={(event) => {
                    console.log(event);
                    setPhoto(event.target.files[0]);
                  }}
                  id="uploadImage"
                  className="is-hidden"
                  disabled={formDisabled}
                />
                <label
                  htmlFor="uploadImage"
                  className="button is-primary w-100"
                  disabled={formDisabled}
                >
                  Bild auswählen
                </label>
                {photo?.name && (
                  <span>
                    <button
                      type="button"
                      className="delete is-medium"
                      aria-label="delete"
                      onClick={() => setPhoto(null)}
                      disabled={formDisabled}
                    ></button>
                  </span>
                )}
              </div>
              {preview && (
                <div className="block">
                  <img src={preview} alt="preview" />
                </div>
              )}
            </div>
          </div>
          <div className="field">
            <div className="control">
              <label
                htmlFor="uploadText"
                className="label"
                disabled={formDisabled}
              >
                Dein Text *
              </label>
              <textarea
                id="uploadText"
                className="textarea"
                placeholder="Beschreibe deine Tomate"
                onChange={(event) => setFormText(event.target.value)}
                disabled={formDisabled}
                value={formText}
              ></textarea>
              <p className="help">* erforderlich</p>
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 button is-fullwidth is-primary"
            disabled={formText === '' || formDisabled || !photo}
          >
            hochladen
          </button>
        </form>
      </div>
    </article>
  );
};

export default AddItem;
