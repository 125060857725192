import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/es/integration/react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Loading from './components/loading';
import Home from './sites/home';
import MyTeam from './sites/my-team';
import Team from './sites/team';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'team',
        element: <MyTeam />,
      },
      {
        path: 'team/:teamId',
        element: <Team />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.querySelector('#root'));
root.render(
  <React.StrictMode>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Provider store={store}>
        <GoogleOAuthProvider clientId="79978479658-o71m557ltsm5u2513epb00eud5mgakts.apps.googleusercontent.com">
          <RouterProvider router={router} />
        </GoogleOAuthProvider>
      </Provider>
    </PersistGate>
  </React.StrictMode>
);
