import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './reducers/login-slice';
import userReducer from './reducers/user-slice';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistCombineReducers,
} from 'redux-persist';
import teamListSlice from './reducers/team-list-slice';
import tomatoListSlice from './reducers/tomato-list-slice';
import teamSlice from './reducers/team-slice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistCombineReducers(persistConfig, {
  login: loginReducer,
  user: userReducer,
  team: teamSlice,
  teamList: teamListSlice,
  tomatoList: tomatoListSlice,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
