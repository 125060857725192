import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleTeamRequest,
  selectApplicationRequests,
} from '../redux/reducers/user-slice';

const ApplicationRequests = () => {
  const applicationRequests = useSelector(selectApplicationRequests);
  const dispatch = useDispatch();

  if (applicationRequests) {
    return (
      <div className="block">
        <span className="has-text-weight-bold">Einladungsanfragen: </span>
        {applicationRequests.map((request) => (
          <div key={request.id} className="card is-shadowless">
            <div className="card-content level p-3 is-justify-content-flex-start">
              <div className="level-left">
                <figure className="image is-32x32 level-item">
                  <img
                    src={request.profile_picture}
                    className="is-rounded"
                    alt="user"
                  />
                </figure>
              </div>
              <div lassName="level-right">
                {' '}
                <span className="level-item">
                  {request.name} will deinem Team beitreten
                </span>
              </div>
            </div>
            <footer className="card-footer">
              <button
                className="button card-footer-item is-small is-success"
                onClick={() =>
                  dispatch(
                    handleTeamRequest({
                      targetUserId: request.id,
                      method: 'accept',
                    })
                  )
                }
              >
                ja
              </button>
              <button
                className="button card-footer-item is-small is-danger"
                onClick={() =>
                  dispatch(
                    handleTeamRequest({
                      targetUserId: request.id,
                      method: 'deny',
                    })
                  )
                }
              >
                nein
              </button>
            </footer>
          </div>
        ))}
      </div>
    );
  }
};

export default ApplicationRequests;
