import React, { useEffect } from 'react';
import ApplicationRequests from './application-requests';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTeamRequests,
  selectApplicationRequests,
} from '../redux/reducers/user-slice';
import { isLoggedInSelector } from '../redux/reducers/login-slice';

const UserNotifications = () => {
  const applicationRequests = useSelector(selectApplicationRequests);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getTeamRequests());
    }
  }, [dispatch, isLoggedIn]);

  return (
    applicationRequests && (
      <div className="box is-shadowless">
        <h5 className="title is-5">Neuigkeiten</h5>
        <ApplicationRequests />
      </div>
    )
  );
};

export default UserNotifications;
