import { API_HOST } from '../../config/settings';

export const getPlantTypes =
  ({ onSuccess }) =>
  (dispatch, getState) => {
    fetch(`${API_HOST}/plants/types`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((data) => {
        onSuccess(data.data);
      })
      .catch((error) => {});
  };
