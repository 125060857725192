import { useDispatch, useSelector } from 'react-redux';
import { logoutAll } from '../redux/reducers/login-slice';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import teamRequestStatus from '../config/TeamRequestStatus';
import { removeUserFromTeam } from '../redux/reducers/user-slice';

const Profile = () => {
  const [deleteModal, toggleDeleteModal] = useState(false);
  const { user } = useSelector((store) => store);
  const dispatch = useDispatch();

  return (
    <div className="box is-shadowless">
      <div className="block is-flex is-flex-direction-row  is-justify-content-space-around">
        <div className="is-flex is-flex-direction-column is-align-items-center mr-4">
          <figure className="image is-96x96">
            <img
              className="is-rounded"
              src={`${user.profile?.picture}`}
              alt="user-profile"
            />
          </figure>
          <button
            className="button is-text mt-2"
            onClick={() => dispatch(logoutAll())}
          >
            Logout
          </button>
        </div>
        <div className="block is-flex is-flex-direction-column w-100">
          <span className="has-text-weight-bold">Dein Team: </span>
          <span>
            {!user.team_name ? (
              <Link to={`/team`}>Team beitreten oder erstellen</Link>
            ) : (
              <>
                {user.team_name}{' '}
                {user.team_request_status === 'requested' && (
                  <span className="tag is-warning">
                    {teamRequestStatus[user.team_request_status || 'accepted']}
                  </span>
                )}{' '}
                <button
                  type="button"
                  className="delete is-small ml-2"
                  onClick={() => toggleDeleteModal(!deleteModal)}
                ></button>
                {user.team_members && (
                  <table className="table is-fullwidth is-narrow">
                    <tbody>
                      {user?.team_members.map((teamMember) => {
                        return (
                          <tr key={teamMember.name}>
                            <td>{teamMember.name}</td>
                            <td>
                              {
                                teamRequestStatus[
                                  teamMember.team_request_status
                                ]
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </span>
        </div>
      </div>
      <div className={`modal${deleteModal ? ' is-active' : ''}`}>
        <div
          className="modal-background"
          onClick={() => toggleDeleteModal(!deleteModal)}
        ></div>
        <div className="modal-content">
          <article className="message is-warning">
            <div className="message-header">
              <p>Team wirklich verlassen?</p>
            </div>
            <div className="message-body">
              <p>Möchtest du das Team wirklich verlassen?</p>
              <p>
                Wenn du das Team verlässt, muss dich ein anderes Team-Mitglied
                erst wieder freischalten damit du wieder beitreten kannst.
              </p>
              <p>
                Solltest du das letzte Team-Mitglied sein, wird das Team
                gelöscht.
              </p>
              <button
                type="button"
                className="mt-4 button is-fullwidth is-danger"
                onClick={() => {
                  dispatch(removeUserFromTeam());
                  toggleDeleteModal(!deleteModal);
                }}
              >
                Team verlassen
              </button>
            </div>
          </article>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => toggleDeleteModal(!deleteModal)}
        ></button>
      </div>
    </div>
  );
};

export default Profile;
