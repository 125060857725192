import React, { useEffect } from 'react';
import Teams from '../components/teams';
import { useDispatch, useSelector } from 'react-redux';
import { isAcceptedInTeam, selectTeam } from '../redux/reducers/user-slice';
import PlantList from '../components/plants/plant-list';
import { getTeamPlants } from '../redux/reducers/team-slice';

const MyTeam = () => {
  const { name: team_name } = useSelector(selectTeam);
  const teamPlants = useSelector((state) => state.team.plants);
  const isAccepted = useSelector(isAcceptedInTeam);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTeamPlants());
  }, [dispatch]);

  if (!isAccepted) {
    return <Teams asSite />;
  }

  return (
    <div className="section pt-0">
      <div className="columns is-centered">
        <div className="column is-12-mobile is-10-tablet is-8-widescreen is-7-fullhd">
          <h1 className="title">{team_name}</h1>
          <h2 className="subtitle">Eure Pflanzen</h2>
          <PlantList teamPlants={teamPlants} isUserTeam />
        </div>
      </div>
    </div>
  );
};

export default MyTeam;
