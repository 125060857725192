import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPlantToTeam } from '../../redux/reducers/team-slice';

const AddPlant = ({
  closeCallback,
  plantTypes,
  defaults: { defaultName, defaultType, defaultHeight },
}) => {
  const [formName, setFormName] = useState(defaultName);
  const [formType, setFormType] = useState(defaultType);
  const [formHeight, setFormHeight] = useState(defaultHeight);
  const dispatch = useDispatch();

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => closeCallback()}></div>
      <div className="modal-content">
        <article className="message">
          <div className="message-header">
            <p>Pflanze anlegen</p>
            <button
              className="delete"
              aria-label="delete"
              onClick={() => closeCallback()}
            ></button>
          </div>
          <div className="message-body">
            <form
              onSubmit={(e) => {
                dispatch(
                  addPlantToTeam(
                    {
                      height: formHeight,
                      name: formName,
                      type: formType,
                    },
                    closeCallback
                  )
                );
                e.preventDefault();
              }}
            >
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Name</label>
                </div>
                <div className="field-body">
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Text input"
                      defaultValue={formName}
                      onChange={(e) => {
                        setFormName(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Sorte</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select">
                        <select
                          onChange={(e) => {
                            setFormType(e.target.value);
                          }}
                        >
                          {plantTypes?.map((plantType) => (
                            <option
                              key={plantType.number}
                              value={plantType.id}
                              selected={plantType.number === formType}
                            >
                              {plantType.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Höhe</label>
                </div>
                <div className="field-body field has-addons">
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      inputMode="numeric"
                      pattern="^\d+(,\d{1,2})?$"
                      placeholder="Pflanzenhöhe"
                      defaultValue={formHeight}
                      title="Gib die Pflanzengröße in cm an."
                      onChange={(e) => {
                        setFormHeight(e.target.value);
                      }}
                    />
                  </div>
                  <div className="control">
                    <a className="button is-static">cm</a>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="mt-4 button is-fullwidth is-primary"
              >
                fertig
              </button>
            </form>
          </div>
        </article>
      </div>
    </div>
  );
};

export default AddPlant;
