import React, { useEffect } from 'react';
import TomatoListItem from './tomato-list-item';
import { useDispatch, useSelector } from 'react-redux';
import { requestTomatoListItems } from '../../redux/reducers/tomato-list-slice';
import { selectAccessToken } from '../../redux/reducers/login-slice';
import { selectUserId } from '../../redux/reducers/user-slice';
import AddItem from './add-item';

const TomatoList = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(selectAccessToken);
  const userId = useSelector(selectUserId);
  const tomatoList = useSelector((state) => state.tomatoList.items);

  useEffect(() => {
    if (accessToken && userId) {
      dispatch(requestTomatoListItems());
    }
  }, [accessToken, userId, dispatch]);

  return (
    <div>
      <AddItem />
      {tomatoList.length === 0 && (
        <progress className="progress is-small is-primary" max="100">
          15%
        </progress>
      )}
      {tomatoList.map((tomatoPost) => (
        <TomatoListItem post={tomatoPost} key={tomatoPost.id} />
      ))}
    </div>
  );
};

export default TomatoList;
