import { createSlice } from '@reduxjs/toolkit';
import { selectTeamId } from './user-slice';
import { API_HOST } from '../../config/settings';
import { logoutAll, selectAccessToken } from './login-slice';

const initialState = {
  plants: null,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState: initialState,
  reducerPath: 'team',
  reducers: {
    setPlants: (state, action) => {
      state.plants = action.payload;
    },
    logout: () => initialState,
  },
});

export const { setPlants, logout } = teamSlice.actions;

export const getTeamPlants = (teamId) => (dispatch, getState) => {
  const accessToken = selectAccessToken(getState());

  let urlTeamId;
  if (!teamId) {
    urlTeamId = selectTeamId(getState());
  } else {
    urlTeamId = teamId;
  }

  if (urlTeamId && accessToken) {
    fetch(`${API_HOST}/teams/${urlTeamId}/plants?access_token=${accessToken}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((json) => {
        dispatch(setPlants(json.data));
      })
      .catch((error) => {
        dispatch(logoutAll());
      });
  }
};

export const addPlantToTeam =
  ({ name, height, type }, onSuccess) =>
  (dispatch, getState) => {
    const teamId = selectTeamId(getState());
    const accessToken = selectAccessToken(getState());
    if (accessToken && teamId) {
      fetch(`${API_HOST}/teams/${teamId}/plants?access_token=${accessToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, height, type }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response);
          }
          return response.json();
        })
        .then((json) => {
          dispatch(setPlants(json.data));
          onSuccess();
        })
        .catch((error) => {});
    }
  };

export const trackPlantHeight =
  ({ plantId, height, date }, onSuccess) =>
  (dispatch, getState) => {
    const accessToken = selectAccessToken(getState());
    fetch(`${API_HOST}/plants/${plantId}/height?access_token=${accessToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ height, date }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((json) => {
        dispatch(setPlants(json.data));
        onSuccess();
      })
      .catch((error) => {});
  };

export default teamSlice.reducer;
