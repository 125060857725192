import { useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import { loginAndRequestProfile } from '../redux/reducers/login-slice';
import { useDispatch } from 'react-redux';

const Login = () => {
  const dispatch = useDispatch();

  const loginSuccess = (credentialResponse) => {
    console.log('login and request profile', credentialResponse.access_token);
    dispatch(loginAndRequestProfile(credentialResponse.access_token));
  };

  const googleLogin = useGoogleLogin({
    onSuccess: loginSuccess,
  });

  const login = () => {
    googleLogin();
  };

  return (
    <div className="section">
      <div className="columns">
        <div className="box column is-shadowless is-half is-offset-one-quarter">
          <h1 className="title is-h1">Login</h1>
          <p className="block">
            Bitte melde dich an um diese Seite benutzen zu können.
          </p>
          <button
            className="block button is-primary is-fullwidth"
            onClick={() => login()}
          >
            login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
