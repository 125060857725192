import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import {
  isLoggedInSelector,
  requestProfile,
} from './redux/reducers/login-slice';
import Navbar from './components/navbar';
import { Outlet } from 'react-router-dom';
import Login from './components/login';

const App = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('login old session');
    dispatch(requestProfile());
  }, [dispatch]);

  return (
    <div className="App">
      <Navbar />
      {isLoggedIn ? <Outlet /> : <Login />}
    </div>
  );
};

export default App;
