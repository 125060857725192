import React, { useState } from 'react';
import { IMAGE_HOST } from '../../config/settings';
import { Link } from 'react-router-dom';

const TomatoListItem = ({ post }) => {
  const [zoomed, setZoomed] = useState(false);
  const [profileImage, setProfileImage] = useState(post.author.profile_picture);

  const renderCreatedAt = () => {
    const date = new Date(post.created_at);
    return date.toLocaleString();
  };

  return (
    <section
      className={`block ${zoomed ? 'is-zoomed cursor-zoom-out' : ''}`}
      onClick={() => zoomed && setZoomed(false)}
    >
      <figure className="image block is-relative">
        <img
          className={`is-rounded-edge cursor-zoom-${zoomed ? 'out' : 'in'}`}
          src={`${IMAGE_HOST}${post.image_url}`}
          alt="Example"
          onClick={() => setZoomed(!zoomed)}
        />
        <article className="media block picture-description p-2">
          <figure className="media-left">
            <p className="image is-64x64">
              <img
                src={profileImage}
                className="is-rounded"
                alt="user"
                onError={(e) => {
                  e.stopPropagation();
                  setProfileImage(
                    process.env.PUBLIC_URL + '/generic-user-profile.png'
                  );
                }}
              />
            </p>
          </figure>
          <div className="media-content">
            <div className="content has-text-dark">
              <p>
                <strong className="has-text-primary">{post.author.name}</strong>{' '}
                <small>
                  <Link
                    to={`/team/${post.author?.team?.id}`}
                    className="has-text-primary	is-underlined"
                  >
                    {post.author?.team?.name}
                  </Link>
                </small>{' '}
                <small> - {renderCreatedAt()}</small>
                <br />
                <span className="is-size-7-touch">{post.text}</span>
              </p>
            </div>
          </div>
        </article>
      </figure>
    </section>
  );
};

export default TomatoListItem;
