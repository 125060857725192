import { createSlice } from '@reduxjs/toolkit';
import { API_HOST } from '../../config/settings';
import { logoutAll, selectAccessToken } from './login-slice';

const initialState = {
  items: [],
};

export const tomatoListSlice = createSlice({
  name: 'tomatoList',
  initialState: initialState,
  reducerPath: 'tomatoList',
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
    logout: () => initialState,
  },
});

export const requestTomatoListItems = () => (dispatch, getState) => {
  const accessToken = selectAccessToken(getState());
  if (accessToken) {
    fetch(`${API_HOST}/posts?access_token=${accessToken}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((json) => {
        dispatch(setItems(json.data));
      })
      .catch((error) => {
        dispatch(logoutAll());
      });
  }
};

export const sendTomatoListItem =
  ({ text, photo, onSuccess }) =>
  (dispatch, getState) => {
    const accessToken = selectAccessToken(getState());

    console.log('text: ', text);
    if (accessToken && text) {
      fetch(`${API_HOST}/posts?access_token=${accessToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(text),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response);
          }
          return response.json();
        })
        .then((json) => {
          const formData = new FormData();
          formData.append('file', photo);

          fetch(
            `${API_HOST}/posts/${json.data}/photo?access_token=${accessToken}`,
            {
              method: 'POST',
              body: formData,
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error(response);
              }
              return response.json();
            })
            .then((json) => {
              dispatch(setItems(json.data));
              onSuccess();
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

export const { logout, setItems } = tomatoListSlice.actions;

export default tomatoListSlice.reducer;
