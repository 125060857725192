import { createSlice } from '@reduxjs/toolkit';
import { logoutAll, selectAccessToken } from './login-slice';
import { router } from '../..';
import { API_HOST } from '../../config/settings';

const initialState = {
  profile: null,
  id: null,
  team: null,
  team_name: null,
  team_request_status: null,
  team_members: null,
  email: null,
  name: null,
  application_requests: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducerPath: 'user',
  reducers: {
    setTeam: (state, action) => {
      if (action.payload) {
        state.team = action.payload.team;
        state.team_name = action.payload.team_name;
      } else {
        state.team = null;
        state.team_name = null;
      }
    },
    setUserData: (state, action) => {
      state.team_name = action.payload.team_name;
      state.team = action.payload.team;
      state.id = action.payload.id;
      state.team_request_status = action.payload.team_request_status;
      state.team_members = action.payload.team_members;
      state.email = action.payload.email;
      state.name = action.payload.name;
    },
    setTeamRequestStatus: (state, action) => {
      state.team_request_status = action.payload;
    },
    setProfile: (state, action) => {
      if (action.payload) {
        state.profile = action.payload;
        console.log('profile set');
      } else {
        state.profile = initialState.profile;
      }
    },
    setApplicationRequests: (state, action) => {
      state.application_requests = action.payload;
    },
    logout: () => initialState,
  },
});

export const getUserDataOrCreateUser =
  (googleProfile) => (dispatch, getState) => {
    fetch(`${API_HOST}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(googleProfile),
    }).then((response) => {
      if (response.status === 400) {
        const accessToken = selectAccessToken(getState());
        fetch(`${API_HOST}/users/user?access_token=${accessToken}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(response);
            }
            return response.json();
          })
          .then((json) => {
            dispatch(setUserData(json.data));
          });
      }
    });
  };

export const selectUserId = (state) => state.user.id;
export const selectTeamId = (state) => state.user.team;

export const createTeamAndAddUser = (teamName) => (dispatch, getState) => {
  const userId = selectUserId(getState());
  if (userId) {
    fetch(`${API_HOST}/teams`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ teamName, user: userId }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        dispatch(setUserData(json.data));
        router.navigate('/');
      });
  }
};

export const removeUserFromTeam = () => (dispatch, getState) => {
  const userId = selectUserId(getState());
  const teamId = selectTeamId(getState());

  fetch(`${API_HOST}/teams/${teamId}/delete?userId=${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
      dispatch(setTeam());
    })
    .catch((error) => {});
};

export const getTeamRequests = () => (dispatch, getState) => {
  console.log('getting team requests');
  const accessToken = selectAccessToken(getState());
  const userId = selectUserId(getState());

  if (userId && accessToken) {
    fetch(`${API_HOST}/users/${userId}/teamRequest?access_token=${accessToken}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((json) => {
        dispatch(setApplicationRequests(json.data));
      })
      .catch((error) => {
        dispatch(logoutAll());
      });
  }
};

export const requestTeamApplication = (teamId) => (dispatch, getState) => {
  const userId = selectUserId(getState());

  fetch(`${API_HOST}/teams/${teamId}/apply?userId=${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
      return response.json();
    })
    .then((data) => {
      dispatch(setUserData(data.data));

      router.navigate('/');
    })
    .catch((error) => {});
};

export const handleTeamRequest =
  ({ targetUserId, method }) =>
  (dispatch, getState) => {
    const accessToken = selectAccessToken(getState());
    const userId = selectUserId(getState());
    fetch(
      `${API_HOST}/users/${userId}/teamRequest/${targetUserId}/${method}?access_token=${accessToken}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.json();
      })
      .then((json) => {
        dispatch(setApplicationRequests(json.data));
      })
      .catch((error) => {
        dispatch(logoutAll());
      });
  };

export const selectApplicationRequests = (state) => {
  const user = state.user;
  if (
    user.application_requests &&
    user.application_requests.length > 0 &&
    user.team_name
  ) {
    return user.application_requests;
  }
  return null;
};

export const selectTeam = (state) => ({
  name: state.user.team_name,
  id: state.user.team,
});

export const isAcceptedInTeam = (state) =>
  (state.user.team_request_status === null ||
    state.user.team_request_status === 'accepted') &&
  state.user.team_name &&
  state.user.team;

export const {
  logout,
  setTeam,
  setTeamRequestStatus,
  setProfile,
  setUserData,
  setApplicationRequests,
} = userSlice.actions;

export default userSlice.reducer;
