import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AddPlant from './add-plant';
import { getPlantTypes } from './get-plant-types';
import TrackPlantHeight from './track-plant-height';
import HeightChart from './height-chart';

const PlantList = ({ teamPlants, isUserTeam }) => {
  const [addPlantModalOpen, setAddPlantModalOpen] = useState(false);
  const [trackHeightModalOpen, setTrackHeightModalOpen] = useState(false);
  const [trackPlant, setTrackPlant] = useState(null);
  const [plantTypes, setPlantTypes] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlantTypes({ onSuccess: setPlantTypes }));
  }, [dispatch, setPlantTypes]);

  const renderDate = (date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleString();
  };

  const renderAddPlantModal = () =>
    addPlantModalOpen && (
      <AddPlant
        closeCallback={() => setAddPlantModalOpen(false)}
        plantTypes={plantTypes}
        defaults={{
          defaultName: `Pflanze #${teamPlants.length + 1}`,
          defaultHeight: '10,00',
          defaultType: '1',
        }}
      />
    );

  const renderHeightLogModal = () =>
    trackHeightModalOpen && (
      <TrackPlantHeight
        closeCallback={() => setTrackHeightModalOpen(false)}
        defaults={{
          defaultHeight: '10,00',
        }}
        plantId={trackPlant?.id}
        plantName={trackPlant?.name}
      />
    );

  return (
    <>
      {isUserTeam && (
        <div className="block">
          <button
            className="button is-primary w-100"
            type="button"
            onClick={() => setAddPlantModalOpen(true)}
          >
            Neue Pflanze anlegen
          </button>
        </div>
      )}
      {teamPlants?.length > 0 ? (
        <div className="box is-shadowless">
          {teamPlants?.map((plant, index) => {
            return (
              <div className="block">
                <table key={plant.name} className="table w-100">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{plant.name}</td>
                    </tr>
                    <tr>
                      <td>Sorte</td>
                      <td>
                        Typ {plant.type.number} - {plant.type.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Höhe</td>
                      <td>
                        {plant.height &&
                          (plant.height[0]?.height_in_mm / 10).toLocaleString(
                            'de-DE'
                          )}{' '}
                        cm{' '}
                        <span className="is-size-7">
                          ({plant.height && renderDate(plant.height[0]?.date)})
                        </span>
                        {isUserTeam && (
                          <button
                            type="button"
                            className="button is-small"
                            onClick={() => {
                              setTrackHeightModalOpen(true);
                              setTrackPlant({ id: plant.id, name: plant.name });
                            }}
                            style={{ float: 'right' }}
                          >
                            Neue Höhe tracken
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <HeightChart plant={plant} isOpenDefault={index === 0} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="block">Noch keine Pflanzen vorhanden.</div>
      )}
      {renderAddPlantModal()}
      {renderHeightLogModal()}
    </>
  );
};

export default PlantList;
