import React, { useEffect, useState } from 'react';
import CreateTeam from './create-team';
import { requestTeamList } from '../redux/reducers/team-list-slice';
import { useDispatch, useSelector } from 'react-redux';
import { requestTeamApplication } from '../redux/reducers/user-slice';
import { Link } from 'react-router-dom';

const Teams = ({ asSite }) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const dispatch = useDispatch();
  const { list } = useSelector((store) => store.teamList);
  const { team_name: selectedTeam } = useSelector((store) => store.user);

  useEffect(() => {
    dispatch(requestTeamList());
  }, [dispatch]);

  return (
    <div className={`${asSite ? 'section' : 'box is-shadowless'}`}>
      <div className="container">
        {asSite ? (
          <h1 className="title">Teams</h1>
        ) : (
          <h5 className="title is-5">Teams</h5>
        )}

        <div className={`${asSite ? 'box is-shadowless' : ''}`}>
          {asSite && (
            <div className="block">
              <CreateTeam />
            </div>
          )}
          <div className="field">
            <input
              className="input is-rounded"
              type="search"
              placeholder="Team suchen"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className={`${asSite ? 'section' : ''}`}>
            {list ? (
              <table className="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th></th>
                    <th>Team</th>
                    <th># Nutzer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {list
                    .filter((team) => {
                      return (
                        searchTerm === null ||
                        team.name
                          .toLowerCase()
                          .search(searchTerm?.toLowerCase()) >= 0
                      );
                    })
                    .map((team, index) => {
                      return (
                        <tr key={team.id}>
                          <th>{index + 1}</th>
                          <td>
                            <Link
                              to={`/team/${team.id}`}
                              className="has-text-primary is-underlined"
                            >
                              {team.name}
                            </Link>
                          </td>
                          <td>{team.userCount}</td>
                          <td>
                            {!selectedTeam && (
                              <button
                                className="button is-small"
                                type="button"
                                onClick={() =>
                                  dispatch(requestTeamApplication(team.id))
                                }
                              >
                                beitreten
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <progress className="progress is-primary" max="100" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
