import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Navbar = () => {
  const { pathname } = useLocation();

  return (
    <div className="tabs is-centered is-boxed mt-2">
      <ul>
        <li className={`${pathname === '/' ? 'is-active' : ''}`}>
          <NavLink to={`/`}>Home</NavLink>
        </li>
        <li className={`${pathname === '/team' ? 'is-active' : ''}`}>
          <NavLink to={`/team`}>Mein Team</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
