import { googleLogout } from '@react-oauth/google';
import { createSlice } from '@reduxjs/toolkit';
import {
  getUserDataOrCreateUser,
  setProfile,
  logout as logoutUser,
} from './user-slice';
import { logout as logoutTeans } from './team-list-slice';
import { logout as logoutTomatoList } from './tomato-list-slice';

const initialState = {
  accessToken: null,
  isCheckingLoginState: false,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducerPath: 'login',
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload;
      console.log('login success');
    },
    setIsCheckingLoginState: (state, action) => {
      state.isCheckingLoginState = action.payload;
    },
    logout: () => initialState,
  },
});

export const requestProfile = () => (dispatch, getState) => {
  console.log('requesting profile data');
  dispatch(setIsCheckingLoginState(true));

  const accessToken = selectAccessToken(getState());
  if (accessToken) {
    fetch(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }

        return response.json();
      })
      .then((profile) => {
        dispatch(setProfile(profile));
        dispatch(getUserDataOrCreateUser(profile));
        dispatch(setIsCheckingLoginState(false));
      })
      .catch((error) => {
        dispatch(logoutAll());
        dispatch(setIsCheckingLoginState(false));
      });
  } else {
    dispatch(logoutAll());
    dispatch(setIsCheckingLoginState(false));
  }
};

export const selectAccessToken = (state) => state.login.accessToken;
export const selectIsCheckingLoginState = (state) =>
  state.login.isCheckingLoginState;

export const isLoggedInSelector = (state) =>
  state.login.accessToken &&
  state.user.profile !== null &&
  state.user.id !== null;

export const logoutAll = () => (dispatch, getState) => {
  googleLogout();
  const accessToken = selectAccessToken(getState());
  if (accessToken) {
    fetch(`https://accounts.google.com/o/oauth2/revoke?token=${accessToken}`, {
      method: 'POST',
    });
  }
  dispatch(setIsCheckingLoginState(false));
  dispatch(logout());
  dispatch(logoutTeans());
  dispatch(setProfile());
  dispatch(logoutUser());
  dispatch(logoutTomatoList());

  console.log('logged out');
};

export const loginAndRequestProfile = (accessToken) => (dispatch) => {
  dispatch(login(accessToken));
  dispatch(requestProfile());
};

export const { login, logout, setIsCheckingLoginState } = loginSlice.actions;

export default loginSlice.reducer;
