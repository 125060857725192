import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeam } from '../redux/reducers/user-slice';
import PlantList from '../components/plants/plant-list';
import { getTeamPlants } from '../redux/reducers/team-slice';
import { useParams } from 'react-router-dom';
import MyTeam from './my-team';
import {
  requestTeamList,
  selectTeamFromTeamList,
} from '../redux/reducers/team-list-slice';

const Team = () => {
  const { id: userTeam } = useSelector(selectTeam);
  const { teamId } = useParams();
  const { name: teamName, users } = useSelector((state) =>
    selectTeamFromTeamList(state, teamId)
  );
  const teamPlants = useSelector((state) => state.team.plants);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestTeamList());
  }, [dispatch]);

  useEffect(() => {
    if (teamId !== userTeam) {
      dispatch(getTeamPlants(teamId));
    }
  }, [dispatch, teamId, userTeam]);

  if (teamId === userTeam) {
    return <MyTeam />;
  }

  return (
    <div className="section pt-0">
      <div className="columns is-centered">
        <div className="column is-12-mobile is-10-tablet is-8-widescreen is-7-fullhd">
          <h1 className="title">Team: {teamName}</h1>
          <h2 className="subtitle">
            Mitglieder: {users.map((user) => user.name).join(', ')}
          </h2>
          <PlantList teamPlants={teamPlants} isUserTeam={false} />
        </div>
      </div>
    </div>
  );
};

export default Team;
